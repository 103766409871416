import styled from "styled-components"

export const Container = styled.section`
  padding: 0 70px;
`

export const ChooseEvent = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  gap: 10px;
  padding: 20px 0;

  button {
    background: none;
    border: none;
    font-weight: bold;
    position: relative;

    &:after {
      position: absolute;
      bottom: -22px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: var(--lightGreen);
    }

    &:focus {
      outline: none;
    }
  }

  button.upcoming {
    color: ${({ upcomingActive }) =>
      upcomingActive ? "var(--lightGreen)" : "var(--lightText)"};

    &:after {
      content: ${({ upcomingActive }) => (upcomingActive ? '""' : "unset")};
    }
  }

  button.previous {
    color: ${({ previousActive }) =>
      previousActive ? "var(--lightGreen)" : "var(--lightText)"};

    &:after {
      content: ${({ previousActive }) => (previousActive ? '""' : "unset")};
    }
  }
`

export const EventsContainer = styled.div`
  gap: 10px;
  flex-wrap: wrap;
  padding: 30px 0;
  display: ${({ show }) => (show ? "flex" : "none")};

  .customfont {
    font-size: 2.4rem;
    margin-top: 100px;
    margin-bottom: 200px;
    text-align: center;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
  }

  article {
    width: 49%;
    margin-bottom: 30px;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }
`
