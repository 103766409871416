import {
  onSnapshot,
  query,
  collection,
  where,
  limit,
  orderBy,
} from "firebase/firestore"
import { useEffect, useRef, useState } from "react"
import { db } from "../firebase/config"

export const useCollection = (_collection, _query, _limit) => {
  const [documents, setDocumets] = useState([])

  const queryValue = useRef(_query).current

  useEffect(() => {
    let ref = collection(db, _collection)

    if (queryValue && !_limit) {
      ref = query(ref, where(...queryValue))
      if (_collection == "property") {
        ref = query(ref, orderBy("date", "desc"))
      }
    }

    if (_limit && !queryValue) {
      ref = query(ref, limit(_limit))
      if (_collection == "property") {
        ref = query(ref, orderBy("date", "desc"))
      }
    }

    if (_limit && queryValue) {
      ref = query(ref, where(...queryValue), limit(_limit))
      if (_collection == "property") {
        ref = query(ref, orderBy("date", "desc"))
      }
    }

    let results = []
    const unsubscribe = onSnapshot(ref, snapshot => {
      snapshot.forEach(doc => {
        results.push({ id: doc.id, data: doc.data() })
      })
      setDocumets(results)
    })

    return () => unsubscribe()
  }, [_collection, queryValue])

  return new Promise(resolve => {
    resolve(documents)
  })
}
