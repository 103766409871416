import { useState, useEffect } from "react";
import { collection, query, where, getDocs,limit } from "firebase/firestore";
import { db } from "../firebase/config"

const useEvent = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchWidget = async () => {
        const table=[];
        const q = query(collection(db, "event"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            table.push({
                id: doc.id,
                value: doc.data()
              })
        });

        setData(table);
      }
  
      fetchWidget()
  }, []);

  return data;
};

export default useEvent;