import React, { useEffect, useState } from "react"
import EventItem from "../GlobalComponents/EventItem"
import { useCollection } from "../../hooks/useCollection"

import { Container, ChooseEvent, EventsContainer } from "./EventsLisitng.styles"
import useEvent from "../../hooks/useEvent"

const EventsLisiting = props => {
  const Events = useEvent()

  const arabic = props.arabic
  const [upcoming, setUpcoming] = useState(true)
  const [previous, setPrevious] = useState(false)
  const [loadingUpcoming, setLoadingUpcoming] = useState(true)
  const [loadingPrevious, setLoadingPrevious] = useState(true)

  const clickUpcoming = () => {
    setUpcoming(true)
    setPrevious(false)
    setEvents(Events)
  }

  const clickPrevious = () => {
    setUpcoming(false)
    setPrevious(true)
    setEvents(Events)
  }

  const [events, setEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [previousEvents, setPreviousEvents] = useState([])
  useEffect(() => {
    setEvents(Events)
  }, [Events])

  useEffect(() => {
    if (events) {
      let upcoming = []
      let previous = []
      var today = new Date()

      events.forEach(event => {
        if (event.value.name != "test") {
          var event_date = new Date(event.value.date.seconds * 1000)

          if (event_date > today) {
            upcoming.push(event)
          } else {
            previous.push(event)
          }
        }
      })
      setUpcomingEvents(upcoming)
      setPreviousEvents(previous)
    }
    setLoadingUpcoming(false)
    setLoadingPrevious(false)
  }, [events])

  return (
    <Container>
      <div className="container">
        <ChooseEvent upcomingActive={upcoming} previousActive={previous}>
          <button onClick={() => clickUpcoming()} className="upcoming">
            {arabic == false ? "Upcoming Events" : "الأحداث القادمة"}
          </button>
          <button onClick={() => clickPrevious()} className="previous">
            {arabic == false ? "Previous Events" : "الأحداث السابقة"}
          </button>
        </ChooseEvent>
        <EventsContainer show={upcoming}>
          {upcomingEvents &&
            upcomingEvents.map((event, key) => {
              return (
                <EventItem
                  key={key}
                  page="eventlisting"
                  arabic={arabic}
                  event={event}
                />
              )
            })}
          {!upcomingEvents.length && loadingUpcoming && (
            <div className="d-flex justify-center w-100 customfont">
              {arabic == false
                ? "Loading Upcoming events"
                : "تحميل الأحداث القادمة"}
            </div>
          )}
          {!upcomingEvents.length && !loadingUpcoming && (
            <div className="d-flex justify-center w-100 customfont">
              {arabic == false
                ? "Sorry no upcoming events"
                : "آسف لا الأحداث القادمة"}
            </div>
          )}
        </EventsContainer>
        <EventsContainer show={previous}>
          {previousEvents &&
            previousEvents.map(event => {
              return (
                <EventItem
                  page="eventlisting"
                  key={event.id}
                  event={event}
                  arabic={arabic}
                />
              )
            })}
          {!previousEvents.length && loadingPrevious && (
            <div className="d-flex justify-center w-100">
              {arabic == false
                ? "Loading Upcoming events"
                : "تحميل الأحداث القادمة"}
            </div>
          )}
          {!previousEvents.length && !loadingPrevious && (
            <div className="d-flex justify-center w-100 customfont">
              {arabic == false
                ? "Sorry no previous events"
                : "آسف لا أحداث سابقة"}
            </div>
          )}
        </EventsContainer>
      </div>
    </Container>
  )
}

export default EventsLisiting
