import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Link } from "gatsby"
import {
  EventContainer,
  ImageContainer,
  TextContent,
} from "./GlobalComponents.styles"
import Img from "gatsby-image"

import LocationDrop from "../../assets/icons/location-drop.svg"

const EventItem = ({ page, event, arabic }) => {
  let data
  let month = ""
  let monthName = ""
  let day = ""
  let dateDay
  let datemonth
  let id
  if (page == "index") {
    id = event.node.id
    data = event.node
    if (data) {
      dateDay = new Date(data.date).getDate()
      datemonth = new Date(data.date).toLocaleString("en-US", {
        month: "short",
      })
    }
  } else {
    id = event.id

    data = event.value
    dateDay = new Date(data.date.seconds * 1000).getDate()
    datemonth = new Date(data.date * 1000).toLocaleString("en-US", {
      month: "short",
    })
  }
  if (data) {
    // const dateArray = date[0].split("-")
    // month = dateArray[1]
    // day = dateArray[2]
  }
  return (
    <article>
      <EventContainer>
        <ImageContainer>
          <img src={data.thumbnail.url} alt="event" className="" />
          {/*<Img
            fixed={data.localImage.childImageSharp.fixed}
            alt="Home Hero"
            placeholder="tracedSVG"
            className="event-img"
            layout="fullWidth"
          />*/}
          <div className="date">
            <span className="day">{dateDay}</span>
            <span className="month">{datemonth}</span>
          </div>
        </ImageContainer>
        <TextContent>
          <h5>
            <Link to={`/event/${id}`}>{data.name}</Link>
          </h5>
          <div className="location">
            <span className="location">
              <img src={LocationDrop} alt="location drop" />
            </span>
            <span>
              {data.city}, {data.country}
            </span>
          </div>
          <p>{data.topic}</p>
          <Link to={`/attend-event/${id}`} className="attend">
            {arabic == false
              ? "I WOULD LIKE TO ATTEND THIS EVENT"
              : "أود حضور هذا الحدث"}
          </Link>
        </TextContent>
      </EventContainer>
    </article>
  )
}

export default EventItem
